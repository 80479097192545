<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-between mt-n3">
      <div class="col-md-6">
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('days')"
          v-bind:class="{
            'btn-info': btnActive.days,
            'btn-outline-info': btnActive.days == false,
          }"
        >
          Hari Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('month')"
          v-bind:class="{
            'btn-info': btnActive.month,
            'btn-outline-info': btnActive.month == false,
          }"
        >
          Bulan Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('lastMonth')"
          v-bind:class="{
            'btn-info': btnActive.lastMonth,
            'btn-outline-info': btnActive.lastMonth == false,
          }"
        >
          Bulan Kemarin
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('year')"
          v-bind:class="{
            'btn-info': btnActive.year,
            'btn-outline-info': btnActive.year == false,
          }"
        >
          Tahun Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          v-bind:class="{
            'btn-info': btnActive.period,
            'btn-outline-info': btnActive.period == false,
          }"
          @click="btnPeriodeOnClick"
        >
          Periode
        </button>
      </div>

      <div class="col-md-3 col-12">
        <b-input-group>
          <!-- <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template> -->
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Pasien"
            @keyup="patientOnKeyUp"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-3 col-12">
        <b-input-group>
          <!-- <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template> -->
          <treeselect
            v-model="filter.diagnose_id"
            :multiple="true"
            placeholder="Cari Diagnosa"
            :options="diagnoseItems"
            @select="diagnoseOnSelect"
            @deselect="diagnoseOnDeselect"
          />
          <!-- <b-form-input
                    type="text"
                    v-model="filter.diagnose"
                    placeholder="Cari Diagnosa"
                    @keyup="patientOnKeyUp"
                  ></b-form-input> -->
        </b-input-group>
      </div>
    </div>

    <!-- timeseries filter -->
    <div class="row">
      <div class="col-md-9" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="list" variant="success">Cari</b-button>
              <b-button squared @click="resetFilter" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
                <template #append>
                  <b-button squared @click="list" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilter" variant="danger"
                    >Reset</b-button
                  >
                </template>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
    </div>

    <b-table striped hover :items="items" :fields="fields" responsive="sm">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '20%' : '',
            width: field.key === 'title' ? '30%' : '',
          }"
        />
      </template>

      <template #cell(title)="data">
        <span v-html="data.item.list_title"></span>
      </template>

      <template #cell(display_date)="data">
        <span v-if="data.item.time == '00:00'">{{
          new Date(data.item.date).toLocaleString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
        }}</span>
        <span v-if="data.item.time != '00:00'">{{
          new Date(data.item.date).toLocaleString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        }}</span>
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="handleDetail(data.item.id)"
            v-b-tooltip.hover
            title="Detail"
            placement="bottom"
          >
            <i class="fas fa-eye px-0"></i>
          </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            @click="handleEdit(data.item.id)"
            v-b-tooltip.hover
            title="Ubah"
            placement="bottom"
            v-if="manipulateBtn == true"
          >
            <i class="fas fa-edit px-0"></i>
          </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-danger"
            @click="deleteData(data.item.id)"
            v-b-tooltip.hover
            title="Hapus"
            placement="bottom"
            v-if="manipulateBtn == true"
          >
            <i class="fas fa-trash px-0"></i>
          </b-button>

          <b-button
            @click="handleDrawList(data.item.id)"
            class="btn bg-primary text-white"
            >Coretan Dokter</b-button
          >
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-primary"
            variant="primary"
            @click="chooseData(data.item)"
            v-b-tooltip.hover
            title="Detail"
            placement="bottom"
          >
            Pilih
          </b-button>
        </template>
        <template v-if="purpose == 'satusehat'">
          <!-- <b-form-radio
            :name="`radios-${data.item.id}`"
            value="1"
            @change="selectData(data.item)"
            ></b-form-radio
          > -->
          <b-form-checkbox
            :id="`id-${data.item.id}`"
            :name="`name-${data.item.id}`"
            value="1"
            unchecked-value="0"
            @input="selectData($event, data.item)"
          >
          </b-form-checkbox>
        </template>
      </template>
    </b-table>
    <!-- pagination -->
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";

export default {
  props: {
    purpose: {
      type: String,
      default: "master",
    },
    route: {
      type: String,
      default: "medical-records",
    },
  },
  data() {
    return {
      // data
      items: null,
      recordData: 0,
      attachment: "",
      displayImage: [],
      displayAttachment: [],
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
      doctors: [],

      // date
      date: "",
      time: "",

      // collapse
      addCollapse: false,
      editCollapse: false,

      // diagnose
      diagnoseItems: [],

      // other
      dataLoaded: false,
      itemsRecently: [],
      fieldsRecently: [
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "title",
          label: "Uraian ",
          sortable: true,
        },
      ],
      detailPatientXDiagnose: [],
      patientXDiagnose: [],
      fieldsPatientXDiagnose: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        // {
        //   key: "title",
        //   label: "Uraian ",
        //   sortable: true,
        // },

        { key: "actions", label: "Aksi" },
      ],

      error: {
        title: "",
        case: "",
        // date: "",
        patient_id: "",
        attachment: "",
        doctor_id: "",
      },

      form: {
        title: "",
        case: "",
        // date: "",
        appointment_id: this.$route.query.appointment_id ?? "",
        patient_id: this.$route.query.patient_id ?? "",
        doctor_id: this.$route.query.doctor_id
          ? [this.$route.query.doctor_id]
          : [],
      },
      edit: [],
      detail: [],
      // data
      show: true,

      // draw
      options: {
        penColor: "#000000",
      },
      // access management
      manipulateBtn: false,
      // filter
      filter: {
        name: "",
        diagnose_id: [],
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // patient info data
      patient: {
        allergic: this.$route.query.allergy,
        notes: this.$route.query.notes,
      },
      // other
      doctorDisabled: true,
      currentUser: getUser(),
    };
  },
  methods: {
    selectData(evt, item) {
      this.$emit("selectData", evt, item);
      console.log("event", evt);
    },

    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },
    async list() {
      let route;
      // if (this.currentUser.user_type_id == 1) {
      //   route = `medical-records/get-by-doctor/${this.currentUser.user_id}`;
      // } else {
      //   route = "medical-records";
      // }
      route = this.route;

      // stringify diagnose id
      let diagnose_id = JSON.stringify(this.filter.diagnose_id);
      console.log(diagnose_id);

      let filterParams = `&name=${this.filter.name}&diagnose_id=${diagnose_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      if (this.purpose == "satusehat") {
        filterParams += "&is_bridge_upload=0";
      }
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;
      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
      this.dataLoaded = true;
    },
    chooseData(item) {
      this.$emit("chooseData", item);
    },
    diagnoseOnSelect(evt) {
      console.log("CLEAR");
      let diagnose = this.diagnoseItems;
      let found = diagnose.findIndex((x) => x.id == evt.id);
      this.filter.diagnose_id.push(diagnose[found].id);
      console.log(this.filter.diagnose_id);
      this.list();
      this.$root.$emit("setFilterDataOnClick", this.filter);
      // console.log("CLEAR 2");
    },

    diagnoseOnDeselect(evt) {
      let diagnose = this.filter.diagnose_id;
      let found = diagnose.findIndex((x) => x == evt.id);
      this.filter.diagnose_id.splice(found, 1);
      this.list();
      this.$root.$emit("setFilterDataOnClick", this.filter);
    },

    patientOnKeyUp() {
      debounce(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.list();
        this.$root.$emit("setFilterDataOnClick", this.filter);
      }, 500);
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }

      this.$root.$emit("setFilterDataOnClick", this.filter);
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style>
</style>