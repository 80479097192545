<template>
    <div>
        <b-modal id="modalMedicalRecord" size="xl" title="Pilih Rekam Medis" hide-footer>
            <Table purpose="modal" @chooseData="chooseData"/>
        </b-modal>
    </div>
</template>

<script>

import Table from '@/component/patients/TableMedicalRecord2.vue'

export default {

    components: {
        Table
    },

    data() {
        return {
        }
    },

    methods: {

        chooseData(item) {
            this.$emit('chooseData', item)
        },

    }

}
</script>

<style></style>