<template>
  <div>
    <b-modal
      id="modalMedicalActionPdf"
      size="xl"
      title="Pilih Rekam Medis"
      hide-footer
    >
      <div class="row">
        <div class="col-3">
          <b-form-select
            v-model="filter.month"
            class="mb-3"
            @input="inputMonth"
          >
            <b-form-select-option :value="''" disabled
              >Bulan</b-form-select-option
            >
            <b-form-select-option value="1">Januari</b-form-select-option>
            <b-form-select-option value="2">Februari</b-form-select-option>
            <b-form-select-option value="3">Maret</b-form-select-option>
            <b-form-select-option value="4">April</b-form-select-option>
            <b-form-select-option value="5">Mei</b-form-select-option>
            <b-form-select-option value="6">Juni</b-form-select-option>
            <b-form-select-option value="7">Juli</b-form-select-option>
            <b-form-select-option value="8">Agustus</b-form-select-option>
            <b-form-select-option value="9">September</b-form-select-option>
            <b-form-select-option value="10">Oktober</b-form-select-option>
            <b-form-select-option value="11">November</b-form-select-option>
            <b-form-select-option value="12">Desember</b-form-select-option>
          </b-form-select>
        </div>
        <div class="col-3">
          <b-form-select v-model="filter.year" class="mb-3">
            <b-form-select-option :value="''" disabled
              >Tahun</b-form-select-option
            >
            <b-form-select-option value="2020">2020</b-form-select-option>
            <b-form-select-option value="2021">2021</b-form-select-option>
            <b-form-select-option value="2022">2022</b-form-select-option>
            <b-form-select-option value="2023">2023</b-form-select-option>
            <b-form-select-option value="2024">2024</b-form-select-option>
            <b-form-select-option value="2025">2025</b-form-select-option>
            <b-form-select-option value="2026">2026</b-form-select-option>
            <b-form-select-option value="2027">2027</b-form-select-option>
            <b-form-select-option value="2028">2028</b-form-select-option>
            <b-form-select-option value="2029">2029</b-form-select-option>
            <b-form-select-option value="2030">2030</b-form-select-option>
          </b-form-select>
        </div>
        <div class="col">
          <b-button
            class="btn btn-primary"
            variant="primary"
            @click="getDataFixed()"
            :disabled="filter.month != '' && filter.year != '' ? false : true"
          >
            Cari <i class="fas fa-search px-0 ml-1"></i>
          </b-button>
          <b-button
            class="btn btn-info ml-1"
            variant="info"
            @click="print()"
            :disabled="data_fixed.length != 0 ? false : true"
          >
            Cetak <i class="fas fa-print px-0 ml-1"></i>
          </b-button>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-center">
          <h4>
            LAPORAN TINDAKAN BULAN
            <span class="text-uppercase">{{ filter.month_name }}</span>
            {{ filter.year }}
          </h4>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th>TINDAKAN</th>
              <template v-for="(employee, i) in employees">
                <th :key="i">
                  <span>SR. {{ employee.name.split(" ")[0] }}</span>
                </th>
              </template>
            </tr>
          </thead>
          <tr>
            <td>ASISTEN SVF</td>
            <td v-for="(data, i) in data_fixed" :key="`assistant_svf${i}`">
              {{ data.assistant_svf }}
            </td>
          </tr>
          <tr>
            <td>OMPLOOP SVF</td>
            <td v-for="(data, i) in data_fixed" :key="`omploop_svf${i}`">
              {{ data.omploop_svf }}
            </td>
          </tr>
          <tr>
            <td>INFUS SVF</td>
            <td v-for="(data, i) in data_fixed" :key="`infus_svf${i}`">
              {{ data.infus_svf }}
            </td>
          </tr>
          <tr>
            <td>RESVF</td>
            <td v-for="(data, i) in data_fixed" :key="`resvf${i}`">
              {{ data.resvf }}
            </td>
          </tr>
          <tr>
            <td>PRP</td>
            <td v-for="(data, i) in data_fixed" :key="`prp${i}`">
              {{ data.prp }}
            </td>
          </tr>
          <tr>
            <td>AAPRP COVID</td>
            <td v-for="(data, i) in data_fixed" :key="`aaprp_covid${i}`">
              {{ data.aaprp_covid }}
            </td>
          </tr>
          <tr>
            <td>ICT</td>
            <td v-for="(data, i) in data_fixed" :key="`ict${i}`">
              {{ data.ict }}
            </td>
          </tr>
          <tr>
            <td>ESWT REHAB</td>
            <td v-for="(data, i) in data_fixed" :key="`eswt_rehab${i}`">
              {{ data.eswt_rehab }}
            </td>
          </tr>
          <tr v-for="(data, i) in action_types" :key="`${data}-${i}`">
            <td>{{ data }}</td>
            <td v-for="(item, j) in data_fixed" :key="`dynamic${j}`">
              <ul class="ml-3">
                <li>A = {{ item[data].assistant }}</li>
                <li>O = {{ item[data].omploop }}</li>
                <li>P = {{ item[data].nurse }}</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <div id="printMedicalActionPdf" class="d-none">
        <div style="font-size: 10px; padding: 10px">
          <div class="d-flex justify-content-center">
            <h4>
              LAPORAN TINDAKAN BULAN
              <span class="text-uppercase">{{ filter.month_name }}</span>
              {{ filter.year }}
            </h4>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th>TINDAKAN</th>
                <template v-for="(employee, i) in employees">
                  <th :key="i">
                    <span>SR. {{ employee.name.split(" ")[0] }}</span>
                  </th>
                </template>
              </tr>
            </thead>
            <tr>
              <td>ASISTEN SVF</td>
              <td v-for="(data, i) in data_fixed" :key="`assistant_svf${i}`">
                {{ data.assistant_svf }}
              </td>
            </tr>
            <tr>
              <td>OMPLOOP SVF</td>
              <td v-for="(data, i) in data_fixed" :key="`omploop_svf${i}`">
                {{ data.omploop_svf }}
              </td>
            </tr>
            <tr>
              <td>INFUS SVF</td>
              <td v-for="(data, i) in data_fixed" :key="`infus_svf${i}`">
                {{ data.infus_svf }}
              </td>
            </tr>
            <tr>
              <td>RESVF</td>
              <td v-for="(data, i) in data_fixed" :key="`resvf${i}`">
                {{ data.resvf }}
              </td>
            </tr>
            <tr>
              <td>PRP</td>
              <td v-for="(data, i) in data_fixed" :key="`prp${i}`">
                {{ data.prp }}
              </td>
            </tr>
            <tr>
              <td>AAPRP COVID</td>
              <td v-for="(data, i) in data_fixed" :key="`aaprp_covid${i}`">
                {{ data.aaprp_covid }}
              </td>
            </tr>
            <tr>
              <td>ICT</td>
              <td v-for="(data, i) in data_fixed" :key="`ict${i}`">
                {{ data.ict }}
              </td>
            </tr>
            <tr>
              <td>ESWT REHAB</td>
              <td v-for="(data, i) in data_fixed" :key="`eswt_rehab${i}`">
                {{ data.eswt_rehab }}
              </td>
            </tr>
            <tr v-for="(data, i) in action_types" :key="`${data}-${i}`">
              <td>{{ data }}</td>
              <td v-for="(item, j) in data_fixed" :key="`dynamic${j}`">
                <div> A = {{ item[data].assistant }}</div>
                <div> O = {{ item[data].omploop }}</div>
                <div> P = {{ item[data].nurse }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/patients/TableMedicalRecord2.vue";
import moment from "moment";

export default {
  components: {
    Table,
  },

  data() {
    return {
      title: "LAPORAN TINDAKAN BULAN JANUARI 2024",
      filter: {
        month: "",
        month_name: "",
        year: "",
        start_date: "",
        end_date: "",
      },
      data_fixed: [],
      employees: [],
      action_types: [],
    };
  },

  methods: {
    async getDataFixed() {
      this.getEmployee();
      const startDate = moment(
        `${this.filter.year}-${this.filter.month}-01`
      ).startOf("month");

      const endDate = moment(
        `${this.filter.year}-${this.filter.month}-01`
      ).endOf("month");

      this.filter.start_date = startDate.format("YYYY-MM-DD");
      this.filter.end_date = endDate.format("YYYY-MM-DD");
      let filterParams = `start_date=${this.filter.start_date}%2000:00:00&end_date=${this.filter.end_date}%2023:59:59`;
      let response = await module.getMedicalActionReport(
        `medical-action-report?${filterParams}`
      );
      this.data_fixed = response.employees;
      this.action_types = response.action_types;
      console.log("data_fixed", this.data_fixed);
    },
    async getEmployee() {
      let filterParams = `department_id=1&sort_by=name&sort_type=asc&start_date=${this.filter.start_date}%2000:00:00&end_date=${this.filter.end_date}%2023:59:59`;

      this.employees = await module.get(`employees?${filterParams}`);
      console.log("employees", this.employees);
    },
    inputMonth(evt) {
      this.filter.month_name = moment()
        .locale("id")
        .month(evt - 1)
        .format("MMMM");
    },
    print() {
      this.$htmlToPaper("printMedicalActionPdf");
    },
  },
  mounted() {
    // this.get();
    this.getEmployee();
  },
};
</script>

<style></style>